import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import ThemeBox from "../../components/bricks/ThemeBox";
import {themeBright, themeDark} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";
import {Box} from "../../components/xui/Box";
import YoutubeVideo from "../../components/bricks/YoutubeVideo";

const Features = ({location}) => (
  <DefaultLayout
    title="Hero Cards and Journeys"
    description="Learn how Hero Cards can bundle your work and how Journeys make recurring bundles easy to work with."
    location={location}
  >
    <Hero size="sm" title="Hero Cards and Journeys">
      <Box width="100%">
        <YoutubeVideo videoId="9gKdahrV-CU" />
      </Box>
    </Hero>

    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature
        title="The Hero you need"
        childAsDescription
        img="hero-cards/the-hero-you-need.png"
      >
        Hero Cards allow you to bundle up related cards. They are an abstraction layer living
        between decks and card checklists. If you use agile terminology, you might think of them as
        epics. In Codecks there is more to them. In combination with Journeys they allow you to
        construct repeatable asset pipelines.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        title="Always on your side"
        childAsDescription
        img="hero-cards/always-on-your-side.png"
        imgRight
      >
        Hero Cards contain one or more sub cards. You can see the full list of contained sub cards
        and jump between them by opening the Hero sidebar. The sub list shows all contained cards
        including their title, owner, priority, effort and milestone.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeDark} withBorder title="Journeys">
      <ThemedSingleFeature
        title="Step by step"
        childAsDescription
        img="hero-cards/step-by-step.png"
      >
        Journeys are an awesome way for working with Hero Cards. They allow you to template full
        sequences of sub cards.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        title="From zero to Hero"
        childAsDescription
        img="hero-cards/from-zero-to-hero.png"
        imgRight
      >
        Once you’ve triggered a Journey all the cards are created. There are no restrictions in
        regards to what you can do with the cards afterwards. Edit, add or remove sub cards as it
        makes sense to this particular Hero Card.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
