import React from "react";

const YoutubeVideo = ({videoId, autoplay}) => (
  <div
    className="gatsby-resp-iframe-wrapper"
    style={{paddingBottom: "56.25%", position: "relative", height: 0, overflow: "hidden"}}
  >
    <iframe
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
      src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0${
        autoplay ? `&autoplay=1` : ""
      }`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
);

export default YoutubeVideo;
